import Vue from 'vue'
import Vuex from 'vuex'
import Rawdata from './store/rawdata'
import EcTempProduct from './store/ec_temp_product'
import EcProcessedProduct from './store/ec_processed_product'
import DmTempProduct from './store/dm_temp_product'
import DmProcessedProduct from './store/dm_processed_product'
import Deduplicate from './store/deduplicate'
import MdTempProduct from './store/md_temp_product'
import MdPublishProduct from './store/md_published_product'
import PmTempProduct from './store/pm_temp_product'
import PmPublishProduct from './store/pm_processed_product'
import Dmduplicate from './store/dmduplicate'
import WebsiteUrlTemp from './store/website_url_temp'
import WebsiteUrlPublished from './store/website_url_published'
import SocialMediaTemp from './store/social_media_temp'
import SocialMediaPublished from './store/social_media_published'
import DalilyStatus from './store/dalily_status'
import TrendReport from "./store/trend_report"
import UXMediaPublished from "./store/ux_media_published"
import UXMediaTemp from "./store/ux_media_temp"


Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        Rawdata,
        EcTempProduct,
        EcProcessedProduct,
        DmTempProduct,
        DmProcessedProduct,
        Deduplicate,
        Dmduplicate,
        MdTempProduct,
        MdPublishProduct,
        PmTempProduct,
        PmPublishProduct,
        WebsiteUrlTemp,
        WebsiteUrlPublished,
        SocialMediaTemp,
        SocialMediaPublished,
        DalilyStatus,
        TrendReport,
        UXMediaPublished,
        UXMediaTemp,
    }
})
