var productrouteconst = {
    CORE_APPROVED_ROUTE_NAME: "ProcessProduct",
    CORE_UNAPPROVED_ROUTE_NAME: "CoreUnApproved",
    CORE_PROBLEM_ROUTE_NAME: "CoreProblem",
    CORE_REPROCESSED_ROUTE_NAME: "CoreReprocessed",
    CORE_FTP_ROUTE_NAME: "CoreFTP",
    /** non core */
    NONCORE_APPROVED_ROUTE_NAME: "NonCoreApproved",
    NONCORE_UNAPPROVED_ROUTE_NAME: "NonCoreUnApproved",
    NONCORE_PROBLEM_ROUTE_NAME: "NonCoreProblem",
    NONCORE_REPROCESSED_ROUTE_NAME: "NonCoreReprocessed",
    NONCORE_FTP_ROUTE_NAME: "NonCoreFTP",

    /** telecom */
    TELECOM_APPROVED_ROUTE_NAME: "TelecomApproved",
    TELECOM_UNAPPROVED_ROUTE_NAME: "TelecomUnApproved",
    TELECOM_PROBLEM_ROUTE_NAME: "TelecomProblem",
    TELECOM_REPROCESSED_ROUTE_NAME: "TelecomReprocess",
    TELECOM_FTP_ROUTE_NAME: "TelecomFTP",

    /** travel */
    TRAVEL_APPROVED_ROUTE_NAME: "TravelApproved",
    TRAVEL_UNAPPROVED_ROUTE_NAME: "TravelUnApproved",
    TRAVEL_PROBLEM_ROUTE_NAME: "TravelProblem",
    TRAVEL_REPROCESSED_ROUTE_NAME: "TravelReprocess",
    TRAVEL_FTP_ROUTE_NAME: "TravelFTP",

    /** Retail */
    RETAIL_APPROVED_ROUTE_NAME: "RetailApproved",
    RETAIL_UNAPPROVED_ROUTE_NAME: "RetailUnApproved",
    RETAIL_PROBLEM_ROUTE_NAME: "RetailProblem",
    RETAIL_REPROCESSED_ROUTE_NAME: "RetailProcessed",
    RETAIL_FTP_ROUTE_NAME: "RetailFTP",

    /** Energy */
    ENERGY_APPROVED_ROUTE_NAME: "RetailApproved",
    ENERGY_UNAPPROVED_ROUTE_NAME: "RetailUnApproved",
    ENERGY_PROBLEM_ROUTE_NAME: "RetailProblem",
    ENERGY_REPROCESSED_ROUTE_NAME: "RetailProcessed",
    ENERGYFTP_ROUTE_NAME: "RetailFTP",
    /**-1- unused,1- Approved, 2-Unapproved,3-Reprocessed,4-Problem,5-FTP,10-Junk,11-temp product */
    /**
     * 40=>ADDPROUCT,
     * 34=>tempproduct
     * 107=>managepreview
     *
     */
    FRONTEND_MODULES: [40, 34, 107],


    getProductParam: function (routename) {
        switch (routename) {
            /** core sectors */
            case this.CORE_APPROVED_ROUTE_NAME:
                return { 'product_status': '1', 'filter': 'core' }
            case this.CORE_UNAPPROVED_ROUTE_NAME:
                return { 'product_status': '2', 'filter': 'core' }
            case this.CORE_PROBLEM_ROUTE_NAME:
                return { 'product_status': '4', 'filter': 'core' }
            case this.CORE_REPROCESSED_ROUTE_NAME:
                return { 'product_status': '3', 'filter': 'core' }
            case this.CORE_FTP_ROUTE_NAME:
                return { 'product_status': '5', 'filter': 'core' }

            /** non core sectors */
            case this.NONCORE_APPROVED_ROUTE_NAME:
                return { 'product_status': '1', 'filter': 'noncore' }
            case this.NONCORE_UNAPPROVED_ROUTE_NAME:
                return { 'product_status': '2', 'filter': 'noncore' }
            case this.NONCORE_PROBLEM_ROUTE_NAME:
                return { 'product_status': '4', 'filter': 'noncore' }
            case this.NONCORE_REPROCESSED_ROUTE_NAME:
                return { 'product_status': '3', 'filter': 'core' }
            case this.NONCORE_FTP_ROUTE_NAME:
                return { 'product_status': '3', 'filter': 'noncore' }
            /** telecom */
            case this.TELECOM_APPROVED_ROUTE_NAME:
                return { 'product_status': '1', 'filter': 'telecom' }
            case this.TELECOM_UNAPPROVED_ROUTE_NAME:
                return { 'product_status': '2', 'filter': 'telecom' }
            case this.TELECOM_PROBLEM_ROUTE_NAME:
                return { 'product_status': '4', 'filter': 'telecom' }
            case this.TELECOM_REPROCESSED_ROUTE_NAME:
                return { 'product_status': '3', 'filter': 'telecom' }
            case this.TELECOM_FTP_ROUTE_NAME:
                return { 'product_status': '5', 'filter': 'telecom' }
            /* travel */
            case this.TRAVEL_APPROVED_ROUTE_NAME:
                return { 'product_status': '2', 'filter': 'travel' }
            case this.TRAVEL_UNAPPROVED_ROUTE_NAME:
                return { 'product_status': '1', 'filter': 'travel' }
            case this.TRAVEL_PROBLEM_ROUTE_NAME:
                return { 'product_status': '2', 'filter': 'travel' }
            case this.TRAVEL_REPROCESSED_ROUTE_NAME:
                return { 'product_status': '2', 'filter': 'travel' }
            case this.TRAVEL_FTP_ROUTE_NAME:
                return { 'product_status': '2', 'filter': 'travel' }

            /* retail */
            case this.RETAIL_APPROVED_ROUTE_NAME:
                return { 'product_status': '2', 'filter': 'retail' }
            case this.RETAIL_UNAPPROVED_ROUTE_NAME:
                return { 'product_status': '1', 'filter': 'retail' }
            case this.RETAIL_PROBLEM_ROUTE_NAME:
                return { 'product_status': '2', 'filter': 'retail' }
            case this.RETAIL_REPROCESSED_ROUTE_NAME:
                return { 'product_status': '2', 'filter': 'retail' }
            case this.RETAIL_FTP_ROUTE_NAME:
                return { 'product_status': '2', 'filter': 'retail' }

            /* energy */
            case this.ENERGY_APPROVED_ROUTE_NAME:
                return { 'product_status': '2', 'filter': 'core' }
            case this.ENERGY_UNAPPROVED_ROUTE_NAME:
                return { 'product_status': '1', 'filter': 'core' }
            case this.ENERGY_PROBLEM_ROUTE_NAME:
                return { 'product_status': '2', 'filter': 'core' }
            case this.ENERGY_REPROCESSED_ROUTE_NAME:
                return { 'product_status': '2', 'filter': 'core' }
            case this.ENERGYFTP_ROUTE_NAME:
                return { 'product_status': '2', 'filter': 'core' }
            default:
                return {}
        }
    },
    UNUSED: 'UNUSED',
    APPROVED: 'APPROVED',
    UNAPPROVED: 'UNAPPROVED',
    REPROCESSED: 'REPROCESSED',
    PROBLEM: 'PROBLEM',
    FTP: 'FTP',
    JUNK: 'GLACIER',
    TEMP_PRODUCT: 'TEMP_PRODUCT',
    PENDING:'IN_PROGRESS',
    DIRECT_MAIL: 'DIRECT_MAIL',
    PRINT: 'PRINT',
    EMAIL: 'EMAIL',
    ONLINE_DISPLAY: 'ONLINE_DISPLAY',
    SOCIAL_MEDIA: 'SOCIAL_MEDIA',
    SEARCH_ENGINE_MARKETING: 'SEARCH_ENGINE_MARKETING',
    ONLINE_VIDEO: 'ONLINE_VIDEO',
    DASHBOARD_ONLY: 'DASHBOARD_ONLY',
    WEBSITE_URL: 'WEBSITE_URL',
    NON_PANELIST: 'NON_PANELIST',
    UX_Mobile: 'UX_Mobile',
    UX_Desktop: 'UX_Desktop',
    ALL: 'ALL',
    FILE_MANAGE_DESTINATION: "v3/file_uploads/",
    PRODUCT_BUCKET_NAME:"csv2-productcontent-development",
    ATTACHMENT_BASE_PATH:"attachments/",

    product_status: function () {
        let view = this,
            status = [
                view.ALL,
                view.TEMP_PRODUCT,
                view.PROBLEM,
                view.REPROCESSED,
                view.UNAPPROVED,
              //view.NON_PANELIST,
                view.FTP,
                view.JUNK,
                view.PENDING

            ],
            all_status = []
        status.forEach(function (o) {
            let n = o.split("_")
            n = n.map(function (o) {
                let a = o.toLowerCase();
                return a.charAt(0).toUpperCase() + a.slice(1);
            })
            all_status.push({
                key: o,
                value: n.join(" ")
            })
        })
        return all_status
    },
    fetchAllProductStatus: function (view) {
        view.$fetch({ requiresAuth: true }).get(view.$url('SEARCH_FORM_RAWDATA')).then((res) => {
            let resdata = res.data.data
            view.$store.dispatch('Rawdata/setUserData', resdata.userdata)
            view.$store.dispatch('Rawdata/setStateData', resdata.statedata)
            view.$store.dispatch('Rawdata/setProductData', resdata.product_status)
        }).catch((error) => {
            console.error(error);
        });
    }
}

export default productrouteconst
